import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import ContentTitleProduct from '../components/content-title-product'
import Floating from '../components/floating'
import StakewayFooter from '../components/stakeway-footer'
import './research.css'

const Research = (props) => {
  return (
    <div className="research-container1">
      <Helmet>
        <title>Research - Stakeway</title>
        <meta property="og:title" content="Research - Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="research-hero">
        <div className="research-frame1171275211">
          <span className="research-text10">
            <span>RESEARCH</span>
            <br></br>
          </span>
          <img
            alt="cubebitmin17156"
            src="/external/research-1-1500w.png"
            className="research-cubebitmin1"
          />
          <div className="research-frame482057">
            <span className="research-text13">
              <span>Driving Innovation through bleeding edge</span>
              <br></br>
              <span>blockchain research and development</span>
            </span>
          </div>
        </div>
      </div>
      <div className="research-container2">
        <span className="research-text17">
          Stakeway research focuses on advancing cryptographic technologies,
          optimizing blockchain clients and enhancing the security and
          performance of staking networks. Our R&amp;D team drives innovations
          that ensure our infrastructure remains at the cutting edge of the
          decentralized finance ecosystem.
        </span>
        <ContentTitleProduct text="Fundamental Research in Cryptography and Blockchain Technologies"></ContentTitleProduct>
        <span className="research-text18">
          Our research team is committed to exploring and developing advanced
          cryptographic techniques and consensus mechanisms. By focusing on
          areas such as peer-to-peer (P2P) networks, our research efforts aim to
          improve the core technologies that power blockchain infrastructures.
          This enables Stakeway to offer more secure and scalable solutions that
          meet the growing demands of the decentralized finance (DeFi)
          ecosystem.
        </span>
        <ContentTitleProduct text="Optimization of Blockchain Clients and Protocols"></ContentTitleProduct>
        <span className="research-text19">
          Stakeway R&amp;D team is actively involved in the development and
          optimization of core blockchain clients, including projects like
          Erigon. This research has led to significant improvements, such as a
          10x throughput increase for zkEVM protocol networks. By contributing
          to advancements in consensus-level technology, Stakeway helps improve
          the performance and efficiency of the networks it supports, ensuring
          that its infrastructure is always on the cutting edge.
        </span>
        <ContentTitleProduct text="Supporting Complex Migrations and Cross-Chain Security Enhancements"></ContentTitleProduct>
        <span className="research-text20">
          The team’s capabilities extend to handling complex blockchain
          migrations at the consensus level. For instance, Stakeway facilitated
          the Palm Network migration from HyperLedger Besu to zkEVM, ensuring
          seamless integration and enhanced security. Research in cross-chain
          security also plays a key role, with efforts focused on utilizing the
          Bitcoin security model to strengthen Proof-of-Stake networks and
          dApps. This cross-chain approach enhances the security of smaller
          networks and reduces risks like 51% attacks.
        </span>
        <ContentTitleProduct text="Continuous Innovation for Future Solutions"></ContentTitleProduct>
        <span className="research-text21">
          <span>
            Stakeway R&amp;D efforts extend beyond immediate needs, with the
            team continually exploring new solutions such as validator
            consolidation (EIP-725) and future protocol upgrades. These
            innovations aim to further improve validator performance, activation
            times and the overall resilience of blockchain infrastructures. The
            ongoing research ensures that Stakeway remains adaptable and ready
            to tackle the future challenges of blockchain technology and staking
            solutions.
          </span>
          <br></br>
        </span>
        <div className="research-scheme"></div>
        <Floating
          label="Stake with us"
          buttonLink="/book-a-call"
          buttonLabel="Stake now"
          description="With over $1B TVL we’re top 15% Lido validator. Stake safely with Stakeway"
          buttonSubtext="Provide your unique case"
          rootClassName="floatingroot-class-name15"
          className="research-component6"
        ></Floating>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default Research
