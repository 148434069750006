import React from 'react'

import PropTypes from 'prop-types'

import './stakeway-product.css'

const StakewayProduct = (props) => {
  return (
    <div className="stakeway-product-container">
      <div className="stakeway-product-frame1171275250">
        <span className="stakeway-product-text1">{props.label}</span>
        <span className="stakeway-product-text2">{props.text}</span>
        <a href={props.link} className="stakeway-product-link1">
          <div className="stakeway-product-frame1171275247">
            <span className="stakeway-product-text3">Learn more</span>
            <img
              alt="chevronright1110"
              src="/external/chevronright1110-45bv.svg"
              className="stakeway-product-chevronright"
            />
          </div>
        </a>
        <a
          href="/book-a-call"
          target="_blank"
          rel="noreferrer noopener"
          className="stakeway-product-link2"
        >
          <div className="stakeway-product-frame1171275239">
            <span className="stakeway-product-text4">BOOK A CALL</span>
            <img
              alt="roomserviceoutline1110"
              src="/external/roomserviceoutline1110-t2zj.svg"
              className="stakeway-product-roomserviceoutline"
            />
          </div>
        </a>
      </div>
    </div>
  )
}

StakewayProduct.defaultProps = {
  label: 'Custodian',
  text: 'Custodial crypto staking service: Secure asset delegation for client rewards.',
  link: 'https://stakeway.com/custodians',
}

StakewayProduct.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
}

export default StakewayProduct
