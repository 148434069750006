import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import TitleProductHero from '../components/title-product-hero'
import ContentParagraph from '../components/content-paragraph'
import StakewayFooter from '../components/stakeway-footer'
import './terms-of-service.css'

const TermsOfService = (props) => {
  return (
    <div className="terms-of-service-container1">
      <Helmet>
        <title>Terms-of-Service - Stakeway</title>
        <meta property="og:title" content="Terms-of-Service - Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="terms-of-service-content">
        <div className="terms-of-service-hero">
          <div className="terms-of-service-description">
            <TitleProductHero text="TERMS OF SERVICE"></TitleProductHero>
          </div>
          <div className="terms-of-service-container2">
            <span className="terms-of-service-text100">
              Effective Date: January 1, 2023. Version 2.0
            </span>
          </div>
        </div>
        <div className="terms-of-service-container3">
          <ContentParagraph text="These Terms of Service (“Terms”) contain the agreement between you and us regarding the Service described below. Please read these Terms carefully. You form a contract with us when you confirm that you accept these Terms or when you otherwise use the Service."></ContentParagraph>
          <div className="terms-of-service-container4">
            <span className="terms-of-service-text101">
              <span>
                1. The Service: Gateway.FM provides infrastructure services
                (“Service”) for building and scaling Web3 projects, including
                Remote Procedure Calls (RPC) API, which enables Web3
                applications to interact with the blockchain and access user
                data easily.
              </span>
              <br></br>
              <br></br>
              <span>2. The Parties:</span>
              <br></br>
              <span>
                a. You are the party who is accepting these Terms and shall be
                referred to as “you,” “your,” or “user.”
              </span>
              <br></br>
              <span>
                b. We, GATEWAY.FM AS, a company with the registered business
                address: Skagenkaien 35, 4006 Stavanger, Norway , are the party
                that operates the Service and is referred to as “company,” “we,
                “us,” or “our.”
              </span>
              <br></br>
              <br></br>
              <span>
                3. We process information about you under our Privacy Policy,
                published at
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <a
                href="https://gateway.fm/privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
                className="terms-of-service-link"
              >
                https://gateway.fm/privacy-policy
              </a>
              <span>
                . By accepting the Terms, you acknowledge that you have read and
                understood the Privacy Policy.
              </span>
              <br></br>
              <br></br>
              <span>
                4. You must register on our Service, providing your current and
                own email address. You agree to receive emails to confirm the
                registration and ownership of the email address. Completing our
                registration process will create a user account and enable you
                to use the Service.
              </span>
              <br></br>
              <br></br>
              <span>
                5. You are solely responsible for your account on the Service,
                its security, and any activities under your account. You agree
                to notify us immediately of any unauthorized use of your account
                or any other security breach. You agree that we cannot and will
                not be liable for any loss or damage arising from your failure
                to comply with this provision.
              </span>
              <br></br>
              <br></br>
              <span>
                6. By using the Service, you agree to be a responsible user:
              </span>
              <br></br>
              <span>
                a. You will not impersonate any person or entity or misrepresent
                your affiliation with a person or entity.
              </span>
              <br></br>
              <span>
                b. You will not use our Service to obtain, collect, use, or
                distribute other users’ information without their prior consent.
              </span>
              <br></br>
              <span>
                c. You will not disguise your location through IP proxying or
                other similar methods.
              </span>
              <br></br>
              <span>
                d. You will not interfere with or disrupt the Service or the
                servers or networks connected to the Service or take any action
                that imposes, or may impose at our sole discretion, an
                unreasonable or disproportionately large load on our
                infrastructure or any other type of security risk.
              </span>
              <br></br>
              <span>
                e. You will not sell, resell, rent, or charge for our Service.
              </span>
              <br></br>
              <br></br>
              <span>
                7. You agree that you will not use the Service for any purpose
                that is unlawful or prohibited by these Terms. You agree to
                follow all applicable local, state, national, and international
                laws and regulations.
              </span>
              <br></br>
              <br></br>
              <span>
                8. You agree that any legal remedy or liability you seek
                resulting from actions or omissions of other users or third
                parties will be limited to a claim against the particular users
                or third parties who caused you harm. You agree not to attempt
                to impose liability on or seek any legal remedy from us for such
                actions or omissions.
              </span>
              <br></br>
              <br></br>
              <span>
                9. If you are discovered to be undertaking prohibited actions,
                your privileges to use our Service may be terminated or
                suspended. Generally, we will explain any suspension or
                termination, but we reserve the right to suspend or terminate
                any account without notice or explanation.
              </span>
              <br></br>
              <br></br>
              <span>
                10. We shall have the right to use your suggestions on improving
                or adding new features to the Service without any compensation
                or credit to you.
              </span>
              <br></br>
              <br></br>
              <span>
                11. We provide the Service without any warranties or guarantees.
              </span>
              <br></br>
              <span>
                a. We do not guarantee that your use of the Service will be
                problem free. Although we work hard to provide the highest
                quality of services, we cannot and do not guarantee that they
                will work perfectly every time or in every respect.
              </span>
              <br></br>
              <span>
                b. We disclaim all warranties and conditions about the Service,
                including all implied warranties and conditions of
                merchantability, fitness for a particular purpose, title, and
                non-infringement.
              </span>
              <br></br>
              <br></br>
              <span>
                12. We reserve the right at any time to modify or discontinue
                the Service and to terminate your access to the Service, with or
                without notice. We reserve the right to limit the availability
                of the Service in any country where you reside. You agree that
                we shall not be liable to you or any third party for any
                modification, suspension, or discontinuance of the Service.
              </span>
              <br></br>
              <br></br>
              <span>
                13. We may also terminate or suspend your user account for
                inactivity, defined as failing to sign in to the Service for an
                extended time.
              </span>
              <br></br>
              <br></br>
              <span>
                14. We are not responsible for any damages you may incur due to
                your use of the Service.
              </span>
              <br></br>
              <span>
                a. You agree to indemnify and hold our company, its parents,
                subsidiaries, affiliates, officers, and employees harmless from
                any claim, demand, or damage, including reasonable attorneys’
                fees, asserted by any third party due to or arising out of your
                use of or conduct on the Service.
              </span>
              <br></br>
              <span>
                b. In no event shall we be liable for any direct, indirect,
                punitive, incidental, special, consequential damages, or any
                damages whatsoever, including, without limitation, damages for
                loss of use, data, or profits, arising out of or in any way
                connected with the use or performance of the Service, with the
                delay or inability to use the Service, the provision of or
                failure to provide any Service, or for any information,
                software, products, services, and related graphics obtained
                through the Service, or otherwise arising out of the use of the
                Service, whether based on contract, tort, negligence, strict
                liability, or otherwise, even if we have been advised of the
                possibility of damages. Because some jurisdictions do not allow
                the exclusion or limitation of liability for consequential or
                incidental damages, the above limitation may not apply in every
                instance.
              </span>
              <br></br>
              <br></br>
              <span>
                15. If you are dissatisfied with any portion of the Service or
                with any of these Terms, your sole and exclusive remedy will be
                to discontinue using the Service.
              </span>
              <br></br>
              <br></br>
              <span>
                16. These Terms are governed by the laws of Norway without
                giving effect to any conflicts of law principles that may
                require the application of the laws of a different country. The
                United Nations Convention on Contracts for the International
                Sale of Goods does not apply to these Terms. All actions or
                proceedings arising under or related to these Terms must be
                brought in the courts in Oslo, Norway. Each Party hereby agrees
                to irrevocably submit to the jurisdiction and venue of any such
                court in all such actions or proceedings. If any provision of
                these Terms is determined by a court of competent jurisdiction
                to be invalid, illegal, or unenforceable, the remaining
                provisions shall not be affected or impaired
              </span>
              <br></br>
              <br></br>
              <span>
                17. You acknowledge and agree that we may disclose your
                information if required by law or in the good faith belief that
                such disclosure is reasonably necessary to:
              </span>
              <br></br>
              <span>a. enforce these Terms;</span>
              <br></br>
              <span>
                b. respond to claims that information violates the rights of
                third parties;
              </span>
              <br></br>
              <span>
                c. protect the rights, property, or personal safety of our
                company, employees, users, or the public;
              </span>
              <br></br>
              <span>
                d. comply with a current judicial proceeding, a court order, or
                legal process served on us or our website, or in any other case
                when we shall disclose such information according to the lawful
                requirement of public authority, by operation of law, or in any
                other circumstances when required by law;
              </span>
              <br></br>
              <span>
                e. enable the transfer or sale of the Service to another entity.
              </span>
              <br></br>
              <br></br>
              <span>
                18. We may modify these Terms from time to time. The latest
                version of the Terms is published at
                https://gateway.fm/terms-of-service . You are responsible for
                remaining informed of any changes because you are legally
                obligated to abide by the latest version of these Terms. You may
                not assign or transfer your rights under these Terms without
                obtaining our prior written consent, and any purported
                assignment or transfer in violation of this section will be null
                and void.
              </span>
              <br></br>
              <br></br>
              <span>
                19. The Gateway.FM word mark is a trademark of our company in
                Norway and other countries. You agree that this and other
                similar trademarks, logos, brand features, and product and
                service names are our property. Without prior written
                permission, you agree not to display or use them in any manner.
              </span>
              <br></br>
              <br></br>
              <span>
                20. The communications between you and our company use
                electronic means, whether you visit the Service or send us
                emails or whether we post notices on the Service or communicate
                with you via email. For contractual purposes:
              </span>
              <br></br>
              <span>
                a. You consent to receive communications from us in an
                electronic form;
              </span>
              <br></br>
              <span>
                b. You agree that all terms, conditions, agreements, notices,
                disclosures, and other communications we provide to you
                electronically satisfy any legal requirement that such
                communications would satisfy if they were to be in writing. The
                preceding does not affect your statutory rights.
              </span>
              <br></br>
              <br></br>
              <span>
                21. To the extent any disclaimer or limitation of liability does
                not apply, all applicable expressed, implied, and statutory
                warranties will be limited to ninety (90) days after the date
                you first used the Service. No warranties shall apply after such
                period.
              </span>
            </span>
          </div>
        </div>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default TermsOfService
