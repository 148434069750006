import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import FooterLink from './footer-link'
import InputField from './input-field'
import ButtonPrimarySubtitle from './button-primary-subtitle'
import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-container1 ${props.rootClassName} `}>
      <div className="footer-container2">
        <div className="footer-content">
          <div className="footer-column1">
            <div className="footer-products">
              <span className="footer-text1">Products</span>
              <FooterLink className="footer-component10"></FooterLink>
              <a href="/presto#blueprints" className="footer-link1">
                <FooterLink
                  label="Blueprints"
                  className="footer-component11"
                ></FooterLink>
              </a>
              <a
                href="https://stakeway.com"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link2"
              >
                <FooterLink
                  label="Staking"
                  className="footer-component12"
                ></FooterLink>
              </a>
            </div>
            <div className="footer-company">
              <span className="footer-text2">Company</span>
              <Link to="/about1" className="footer-navlink1">
                <FooterLink
                  label="About us"
                  className="footer-component13"
                ></FooterLink>
              </Link>
              <Link to="/book-a-call" className="footer-navlink2">
                <FooterLink
                  label="Book a call"
                  className="footer-component14"
                ></FooterLink>
              </Link>
              <Link to="/blog" className="footer-navlink3">
                <FooterLink
                  label="Blog"
                  className="footer-component15"
                ></FooterLink>
              </Link>
              <a
                href="https://boards.eu.greenhouse.io/gatewayfm"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link3"
              >
                <FooterLink
                  label="Careers"
                  className="footer-component16"
                ></FooterLink>
              </a>
              <Link to="/brand-guidelines" className="footer-navlink4">
                <FooterLink
                  label="Press kit"
                  className="footer-component17"
                ></FooterLink>
              </Link>
              <Link to="/privacy-policy" className="footer-navlink5">
                <FooterLink
                  label="Privacy Policy"
                  className="footer-component18"
                ></FooterLink>
              </Link>
              <Link to="/terms-of-service" className="footer-navlink6">
                <FooterLink
                  label="Terms of Service"
                  className="footer-component19"
                ></FooterLink>
              </Link>
            </div>
          </div>
          <div className="footer-column2">
            <div className="footer-developers">
              <span className="footer-text3">Developers</span>
              <a
                href="https://docs-presto.gateway.fm/"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link4"
              >
                <FooterLink
                  label="Documentation"
                  className="footer-component20"
                ></FooterLink>
              </a>
              <FooterLink
                label="Bounties"
                className="footer-component21"
              ></FooterLink>
            </div>
            <div className="footer-socials">
              <a
                href="https://gatewayfm.medium.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link5"
              >
                <img
                  alt="Medium6985"
                  src="/external/ico-medium.svg"
                  className="footer-medium"
                />
              </a>
              <a
                href="https://x.com/gateway_eth"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link6"
              >
                <img
                  alt="TwitterX6985"
                  src="/external/ico-twitterx.svg"
                  className="footer-twitter-x"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/gateway-fm/"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link7"
              >
                <img
                  alt="LinkedIn6985"
                  src="/external/ico-linkedin.svg"
                  className="footer-linked-in"
                />
              </a>
              <a
                href="https://t.me/gateway_fm"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link8"
              >
                <img
                  alt="Telegram6985"
                  src="/external/ico-telegram.svg"
                  className="footer-telegram"
                />
              </a>
              <a
                href="https://discord.gg/77vtmKNbkf"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link9"
              >
                <img
                  alt="Discord"
                  src="/external/ico-discord.svg"
                  className="footer-discord"
                />
              </a>
            </div>
            <div className="footer-stay-in-touch">
              <span className="footer-text4">Stay in touch</span>
              <span className="footer-text5">
                Sign up for updates of our unbreakable web3 infrastructure
              </span>
              <form id="subscribeForm" className="footer-form">
                <InputField
                  id="subscribeEmail"
                  label="Your email"
                  nameValue="email"
                  placeholder="mail@example.com"
                ></InputField>
                <ButtonPrimarySubtitle
                  main="Sign up"
                  divID="updateSignup"
                  supportive=" "
                ></ButtonPrimarySubtitle>
                <div id="signupMessage" className="footer-message">
                  <span className="footer-text6">
                    <span>It’s on!</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <img
            alt="Frame4814906985"
            src="/external/frame4814906985-ht3.svg"
            className="footer-logo"
          />
        </div>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  rootClassName: '',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
}

export default Footer
