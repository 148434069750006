import React from 'react'

import PropTypes from 'prop-types'

import './navigation-link.css'

const NavigationLink = (props) => {
  return (
    <div className="navigation-link-container">
      <a href={props.link} className="navigation-link-text">
        {props.label}
      </a>
    </div>
  )
}

NavigationLink.defaultProps = {
  label: 'About',
  link: '/#',
}

NavigationLink.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
}

export default NavigationLink
