import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleProductHero from '../components/title-product-hero'
import SubtitleProductHero from '../components/subtitle-product-hero'
import USPCheckmark from '../components/usp-checkmark'
import ButtonPrimarySubtitle from '../components/button-primary-subtitle'
import CardPromo from '../components/card-promo'
import ButtonSecondary from '../components/button-secondary'
import SubtitleProductPromo from '../components/subtitle-product-promo'
import AnnouncementSmall from '../components/announcement-small'
import Testimonial from '../components/testimonial'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './node-sale.css'

const NodeSale = (props) => {
  return (
    <div className="node-sale-container1">
      <Helmet>
        <title>Node-Sale - Stakeway</title>
        <meta property="og:title" content="Node-Sale - Stakeway" />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name36"></Navigation>
      <div className="node-sale-content1">
        <div className="node-sale-hero">
          <div className="node-sale-description">
            <TitleProductHero text="NODE AS A SERVICE"></TitleProductHero>
            <SubtitleProductHero text="Innovative solution to fundrase without token launch and equity giveaways"></SubtitleProductHero>
            <USPCheckmark text="Raise funds with no legal obstacles"></USPCheckmark>
            <USPCheckmark text="Engage community to build network"></USPCheckmark>
            <USPCheckmark text="Support on every step of the way"></USPCheckmark>
            <Link to="/book-a-call" className="node-sale-navlink1">
              <ButtonPrimarySubtitle
                main="Plan your node sale"
                supportive="Free call, no obligations"
                className="node-sale-component16"
              ></ButtonPrimarySubtitle>
            </Link>
          </div>
          <img
            alt="rpc27933"
            src="/external/rpc27933-7m5c-1200w.png"
            className="node-sale-rpc2"
          />
        </div>
        <div className="node-sale-live-sale">
          <a
            href="https://presale.wirexpaychain.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="node-sale-link"
          >
            <div className="node-sale-container2">
              <img
                alt="Vector7934"
                src="/external/vector7934-a04b.svg"
                className="node-sale-vector"
              />
              <div className="node-sale-texts1">
                <div className="node-sale-title1">
                  <span className="node-sale-text10">WIREX NODE SALE</span>
                  <div className="node-sale-value">
                    <span className="node-sale-text11">$12 632 566</span>
                  </div>
                </div>
                <span className="node-sale-text12">
                  Join Wirex Pay to build the largest modular payment ZK chain
                </span>
              </div>
              <img
                alt="arrowright7934"
                src="/external/arrowright7934-986.svg"
                className="node-sale-arrowright"
              />
            </div>
          </a>
        </div>
        <div className="node-sale-container3">
          <CardPromo imageSrc="/external/nodesaleimage8023-byjn-1200w.png"></CardPromo>
          <CardPromo
            label="Engage community to build network"
            imageSrc="/external/team8023-uxbi-1200w.png"
            description="If you have 10’000 node owners, you have 10’000 motivated builders"
          ></CardPromo>
        </div>
        <div className="node-sale-legal-disclaimer">
          <div className="node-sale-container4">
            <div className="node-sale-texts2">
              <div className="node-sale-title2">
                <span className="node-sale-text13">No legal obstacles</span>
              </div>
              <span className="node-sale-text14">
                Node Sales offer a legally straightforward path to network
                growth, simplifying compliance compared to traditional
                token-based fundraising
              </span>
            </div>
          </div>
        </div>
        <div className="node-sale-container5">
          <CardPromo
            label="Infrastructure costs handled by peers"
            imageSrc="/external/coins8023-2pep-1200w.png"
            description="Decentralized network growth with peer-funded infrastructure, reducing central operational expenses"
          ></CardPromo>
          <CardPromo
            label="Support on every step of the way"
            imageSrc="/external/chair8023-g2gh-1200w.png"
            description="End-to-end assistance, from sale setup through launch and ongoing maintenance, ensuring technical and operational success"
          ></CardPromo>
        </div>
        <div className="node-sale-frame1171275261">
          <div className="node-sale-frame481837">
            <div className="node-sale-frame1171275255">
              <div className="node-sale-frame1171275257">
                <span className="node-sale-text15">PR support</span>
              </div>
              <span className="node-sale-text16">
                We can take care of your PR campaign and make loud announcements
                online
              </span>
            </div>
            <img
              alt="rocket17943"
              src="/external/rocket17943-wixp-300h.png"
              className="node-sale-rocket1"
            />
          </div>
        </div>
        <div className="node-sale-advisory">
          <div className="node-sale-container6">
            <div className="node-sale-content2">
              <div className="node-sale-title3">
                <span className="node-sale-text17">
                  <span>NOT SURE WHERE</span>
                  <br></br>
                  <span>TO START?</span>
                </span>
              </div>
              <span className="node-sale-text21">
                <span>Book a free call to get guidance.</span>
                <br></br>
                <span>No obligations.</span>
              </span>
              <Link to="/book-a-call" className="node-sale-navlink2">
                <ButtonSecondary
                  label="Book a free call"
                  subtext="Your personal project manager"
                  rootClassName="button-secondaryroot-class-name2"
                  className="node-sale-component21"
                ></ButtonSecondary>
              </Link>
            </div>
          </div>
        </div>
        <SubtitleProductPromo></SubtitleProductPromo>
        <AnnouncementSmall
          text="We enabled Wirex to elevate its zk-powered network"
          rootClassName="announcement-smallroot-class-name17"
          className="node-sale-component23"
        ></AnnouncementSmall>
        <div className="node-sale-testimonials">
          <Testimonial
            name="Wirex"
            text="Wirex partners with Gateway.fm to elevate its ZK-powered WPay decentralised payment network"
            position="@wirexapp"
            rootClassName="testimonialroot-class-name87"
            logopolygonSrc="/external/vector1317-f6xm.svg"
          ></Testimonial>
          <Testimonial
            name="GPT Protocol"
            text="Great job team! Amazing and dedicated individuals. No wonder they are #1"
            position="@gpt_protocol"
            rootClassName="testimonialroot-class-name88"
            logopolygonSrc="/external/logos/logo-gpt-200w.png"
          ></Testimonial>
          <Testimonial
            name="Dora"
            text="Our partners at @gateway_eth keep on pushing the ZK world forwards 🙌 ...deploy a rollup with @zksync stack"
            position="@SearchOnDora"
            rootClassName="testimonialroot-class-name89"
            logopolygonSrc="/external/logos/logo-dora1-200h.png"
          ></Testimonial>
        </div>
        <Floating
          label="Start planning your Node Sale"
          buttonLink="/book-a-call"
          buttonLabel="Plan your Node Sale"
          description="Innovative solution to fundrase without token launch and equity giveaways"
          buttonSubtext="Free call, no obligations"
          rootClassName="floatingroot-class-name11"
          className="node-sale-component27"
        ></Floating>
      </div>
      <Footer rootClassName="footerroot-class-name35"></Footer>
    </div>
  )
}

export default NodeSale
