import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './chevron-asset-child.css'

const ChevronAssetChild = (props) => {
  return (
    <div
      className={`chevron-asset-child-chevron-asset-child ${props.rootClassName} `}
    >
      <div className="chevron-asset-child-logoethereum">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="chevron-asset-child-image"
        />
      </div>
      <div className="chevron-asset-child-frame1171275268">
        <span id="Ethereum" className="chevron-asset-child-text1">
          {props.text ?? (
            <Fragment>
              <span className="chevron-asset-child-text4">Ethereum</span>
            </Fragment>
          )}
        </span>
        <span id="ETH" className="chevron-asset-child-text2">
          {props.text1 ?? (
            <Fragment>
              <span className="chevron-asset-child-text3">ETH</span>
            </Fragment>
          )}
        </span>
      </div>
    </div>
  )
}

ChevronAssetChild.defaultProps = {
  imageAlt: 'image',
  text1: undefined,
  imageSrc: '/external/logo-ethereum1-200h.png',
  text: undefined,
  rootClassName: '',
}

ChevronAssetChild.propTypes = {
  imageAlt: PropTypes.string,
  text1: PropTypes.element,
  imageSrc: PropTypes.string,
  text: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default ChevronAssetChild
