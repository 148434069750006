import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Careers from './views/careers'
import About from './views/about'
import CareerBusinessDeveloper from './views/careers/career-business-developer'
import NodeSale from './views/node-sale'
import Blog from './views/blog'
import Web3InfrastructureBlueprintViralDigitalAsset from './views/web3-infrastructure-blueprint-viral-digital-asset'
import Infrastructure from './views/infrastructure'
import ContactForm from './views/contact-form'
import Page from './views/page'
import Home from './views/home'
import PosNetworks from './views/pos-networks'
import Custodians from './views/custodians'
import CareerBusinessDevelopmentMarketingManager from './views/careers/career-business-development-marketing-manager'
import BlogStakingMarket from './views/blog-staking-market'
import ThankYou from './views/thank-you'
import BookACall from './views/book-a-call'
import BlogStakingRewards from './views/blog-staking-rewards'
import About1 from './views/about1'
import BlogStakingSecurity from './views/blog-staking-security'
import PrivacyPolicy from './views/privacy-policy'
import Institutions from './views/institutions'
import Research from './views/research'
import TermsOfService from './views/terms-of-service'
import BrandGuidelines from './views/brand-guidelines'
import AssetPage from './views/asset-page'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Careers} exact path="/careers" />
        <Route component={About} exact path="/about" />
        <Route
          component={CareerBusinessDeveloper}
          exact
          path="/careers/business"
        />
        <Route component={NodeSale} exact path="/node-sale" />
        <Route component={Blog} exact path="/blog" />
        <Route
          component={Web3InfrastructureBlueprintViralDigitalAsset}
          exact
          path="/web3-infrastructure-blueprint-viral-digital-asset"
        />
        <Route component={Infrastructure} exact path="/infrastructure" />
        <Route component={ContactForm} exact path="/contact-form" />
        <Route component={Page} exact path="/page" />
        <Route component={Home} exact path="/" />
        <Route component={PosNetworks} exact path="/pos-networks" />
        <Route component={Custodians} exact path="/custodians" />
        <Route
          component={CareerBusinessDevelopmentMarketingManager}
          exact
          path="/careers/business2"
        />
        <Route
          component={BlogStakingMarket}
          exact
          path="/blog-staking-market"
        />
        <Route component={ThankYou} exact path="/thank-you" />
        <Route component={BookACall} exact path="/book-a-call" />
        <Route
          component={BlogStakingRewards}
          exact
          path="/blog-staking-rewards"
        />
        <Route component={About1} exact path="/about1" />
        <Route
          component={BlogStakingSecurity}
          exact
          path="/blog-staking-security"
        />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
        <Route component={Institutions} exact path="/institutions" />
        <Route component={Research} exact path="/research" />
        <Route component={TermsOfService} exact path="/terms-of-service" />
        <Route component={BrandGuidelines} exact path="/brand-guidelines" />
        <Route component={AssetPage} exact path="/asset-page" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
