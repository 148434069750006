import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import ContentTitleProduct from '../components/content-title-product'
import Floating from '../components/floating'
import StakewayFooter from '../components/stakeway-footer'
import './institutions.css'

const Institutions = (props) => {
  return (
    <div className="institutions-container1">
      <Helmet>
        <title>Institutions - Stakeway</title>
        <meta property="og:title" content="Institutions - Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="institutions-hero">
        <div className="institutions-frame1171275211">
          <span className="institutions-text10">
            <span>INSTITUTIONS</span>
            <br></br>
          </span>
          <img
            alt="cubebitmin17156"
            src="/external/city-1500w.png"
            className="institutions-cubebitmin1"
          />
          <div className="institutions-frame482057">
            <span className="institutions-text13">
              Institutional Staking at Stakeway
            </span>
          </div>
        </div>
      </div>
      <div className="institutions-container2">
        <ContentTitleProduct text="Institutional Staking at Stakeway"></ContentTitleProduct>
        <span className="institutions-text14">
          <span>
            Staking is vital to maintaining the security and integrity of the
            Ethereum ecosystem. At Stakeway we aim to support this by providing
            an accessible platform for institutional stakeholders to participate
            seamlessly in the web3 space.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Effortless Access to Top Staking Providers"></ContentTitleProduct>
        <span className="institutions-text17">
          <span>
            Navigating the diverse landscape of staking service providers can be
            daunting. Each provider offers unique configurations, reporting
            standards, fee structures and certifications. Stakeway simplifies
            this by offering a unified marketplace where institutions can easily
            compare and select the best staking service providers.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Why Choose Stakeway?"></ContentTitleProduct>
        <span className="institutions-text20">
          <span>
            Stakeway streamlines the staking process with Standardized Terms
            &amp; Conditions:
          </span>
          <br></br>
          <span>- Ensuring transparency and consistency across providers.</span>
          <br></br>
          <span>
            - Comprehensive Reporting Templates: Facilitating clear and uniform
            reporting.
          </span>
          <br></br>
          <span>
            - Simplified Staking Experience: Making staking as easy as a single
            click.
          </span>
          <br></br>
          <span>
            - Experience our institutional staking marketplace designed to meet
            the needs of organizations, enhancing security and participation in
            the Ethereum ecosystem.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Join Today"></ContentTitleProduct>
        <span className="institutions-text31">
          By consolidating top-tier staking services, Stakeway ensures that
          institutional participants can confidently and efficiently engage with
          the Ethereum ecosystem. Join Stakeway today and take the first step
          towards effortless institutional staking.
        </span>
        <div className="institutions-scheme"></div>
        <Floating
          label="Stake with us"
          buttonLink="/book-a-call"
          buttonLabel="Stake now"
          description="With over $1B TVL we’re top 15% Lido validator. Stake safely with Stakeway"
          buttonSubtext="Provide your unique case"
          rootClassName="floatingroot-class-name14"
          className="institutions-component6"
        ></Floating>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default Institutions
