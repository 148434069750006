import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import ContentTitleProduct from '../components/content-title-product'
import Floating from '../components/floating'
import StakewayFooter from '../components/stakeway-footer'
import './custodians.css'

const Custodians = (props) => {
  return (
    <div className="custodians-container1">
      <Helmet>
        <title>Why Choose Our Staking Integration?</title>
        <meta
          name="description"
          content="Our comprehensive SDK simplifies the management of staking/unstaking transactions, validator interactions and rewards on all major PoS blockchains. Focus yo..."
        />
        <meta
          property="og:title"
          content="Why Choose Our Staking Integration?"
        />
        <meta
          property="og:description"
          content="Our comprehensive SDK simplifies the management of staking/unstaking transactions, validator interactions and rewards on all major PoS blockchains. Focus yo..."
        />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="custodians-hero">
        <div className="custodians-frame1171275211">
          <span className="custodians-text10">
            <span>CUSTODIANS</span>
            <br></br>
          </span>
          <img
            alt="cubebitmin17156"
            src="/external/safe-box-custodians-1500w.png"
            className="custodians-cubebitmin1"
          />
          <div className="custodians-frame482057">
            <span className="custodians-text13">
              <span>Our Solutions Fit</span>
              <br></br>
              <span>Any Custody Framework</span>
            </span>
          </div>
        </div>
      </div>
      <div className="custodians-container2">
        <ContentTitleProduct text="Why Choose Our Staking Integration?"></ContentTitleProduct>
        <span className="custodians-text17">
          <span>1. Unified Staking SDK</span>
          <span className="custodians-text19">
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br className="custodians-text20"></br>
          <span className="custodians-text21">
            Our comprehensive SDK simplifies the management of staking/unstaking
            transactions, validator interactions and rewards on all major PoS
            blockchains. Focus your engineering efforts on innovative projects
            while we handle the complexity.
          </span>
          <br className="custodians-text22"></br>
          <br className="custodians-text23"></br>
          <span className="custodians-text24">2. Enterprise-Grade Service</span>
          <br className="custodians-text25"></br>
          <span className="custodians-text26">
            Enjoy a SOC2 compliant staking solution supported by a cutting-edge
            validator infrastructure, fully managed by our expert DevOps team.
          </span>
          <br className="custodians-text27"></br>
          <br className="custodians-text28"></br>
          <span className="custodians-text29">3. Fully Automated</span>
          <span className="custodians-text30">
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br className="custodians-text31"></br>
          <span className="custodians-text32">
            Automate rewards and commission flows seamlessly through our
            on-chain smart contract solutions.
          </span>
          <br className="custodians-text33"></br>
          <br className="custodians-text34"></br>
          <span className="custodians-text35">
            Staking is a fundamental aspect of the blockchain ecosystem. It
            allows asset owners to secure the network and earn rewards,
            fostering decentralization.
          </span>
          <br className="custodians-text36"></br>
        </span>
        <ContentTitleProduct text="Challenges"></ContentTitleProduct>
        <span className="custodians-text37">
          <span>Custom Integrations</span>
          <br></br>
          <span>
            Each PoS protocol has unique transaction formats, performance
            metrics and reward structures which can complicate integration.
          </span>
          <br></br>
          <br></br>
          <span>Ongoing Maintenance</span>
          <br></br>
          <span>
            Maintaining each protocol requires continuous engineering efforts,
            diverting resources from new projects.
          </span>
          <br></br>
          <br></br>
          <span>Securing Infrastructure</span>
          <br></br>
          <span>
            Ensuring uptime and security in a hostile environment can be
            demanding.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Our solutions"></ContentTitleProduct>
        <span className="custodians-text52">
          <span>Unified SDK</span>
          <br></br>
          <span>
            Streamline transactions across all major PoS blockchains with our
            single SDK, compatible with all node providers.
          </span>
          <br></br>
          <br></br>
          <span>Guaranteed Uptime</span>
          <br></br>
          <span>
            Benefit from our SOC2 Type II certified infrastructure with
            guaranteed uptime SLAs.
          </span>
          <br></br>
          <br></br>
          <span>Automated Processes</span>
          <br></br>
          <span>
            Fully automate rewards and commission flows through our smart
            contracts, reducing costs and operational overhead.
          </span>
          <br></br>
          <br></br>
          <span>
            Increase transparency and revenue while decreasing operational costs
            with our comprehensive staking solutions.
          </span>
          <br></br>
        </span>
        <div className="custodians-scheme"></div>
        <Floating
          label="Stake with us"
          buttonLink="/book-a-call"
          buttonLabel="Stake now"
          description="With over $1B TVL we’re top 15% Lido validator. Stake safely with Stakeway"
          buttonSubtext="Provide your unique case"
          rootClassName="floatingroot-class-name13"
          className="custodians-component5"
        ></Floating>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default Custodians
