import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import StakewayHighlight from '../components/stakeway-highlight'
import Tag from '../components/tag'
import ChevronAssetChild from '../components/chevron-asset-child'
import StakewayCard from '../components/stakeway-card'
import StakewayCardReverse from '../components/stakeway-card-reverse'
import StakewayProduct from '../components/stakeway-product'
import StakewayFooter from '../components/stakeway-footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>Stakeway</title>
        <meta property="og:title" content="Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="home-highlight1">
        <StakewayHighlight
          text="BTC Staking is live"
          buttonLabel="STAKE NOW"
          rootClassName="stakeway-highlightroot-class-name"
        ></StakewayHighlight>
      </div>
      <div className="home-hero">
        <div className="home-left-part">
          <Tag
            text="Institution grade staking"
            rootClassName="tagroot-class-name15"
          ></Tag>
          <span className="home-text10">
            <span>Secure.</span>
            <br></br>
            <span>Tailored.</span>
            <br></br>
            <span>Unslashable</span>
          </span>
          <a
            href="/book-a-call"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link1"
          >
            <div className="home-button">
              <span className="home-text16">STAKE NOW</span>
              <img
                alt="trendingup1067"
                src="/external/trendingup1067-5sa8.svg"
                className="home-trendingup"
              />
            </div>
          </a>
        </div>
        <div className="home-right-part">
          <div id="home-right-part-child1" className="home-frame1171275257">
            <div className="home-frame1171275256">
              <span className="home-text17">
                Enter amount to calculate rewards
              </span>
              <div className="home-frame482019">
                <span className="home-text18">
                  <span>USD</span>
                </span>
              </div>
            </div>
            <div className="home-frame482204">
              <div className="home-frame481578">
                <div className="home-frame11712752541">
                  <div className="home-logoethereum">
                    <img
                      id="home-interactive-element-image"
                      alt="image"
                      src="/external/logos/logo-ethereum-200h.png"
                      className="home-image1"
                    />
                  </div>
                  <div className="home-frame481505">
                    <div className="home-frame4815121">
                      <span
                        id="home-interactive-element-asset-name"
                        className="home-text20"
                      >
                        Ethereum
                      </span>
                    </div>
                    <span
                      id="home-interactive-element-asset-percentage"
                      className="home-text21"
                    >
                      3.43%
                    </span>
                    <span id="home-dropdown-asset-price" className="ratio">
                      123
                    </span>
                  </div>
                </div>
                <div className="home-frame1171275255">
                  <input
                    type="text"
                    id="asset-home-input-amount"
                    placeholder="100"
                    className="home-input input"
                  />
                  <div id="asset-home-drop-down" className="home-container11">
                    <img
                      alt="SVG24iconchevron1110"
                      src="/external/svg24iconchevron1110-uria.svg"
                      className="home-svg24iconchevron"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="asset-dropdown-list" className="home-container12">
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text23">BTC</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text24">2.87%</span>
                  </Fragment>
                }
                imageSrc="/external/btc-logo-200h.png"
                rootClassName="chevron-asset-childroot-class-name"
              ></ChevronAssetChild>
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text25">ETH</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text26">3.43%</span>
                  </Fragment>
                }
                imageSrc="/external/logo-ethereum1-200h.png"
              ></ChevronAssetChild>
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text27">POL</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text28">5.6%</span>
                  </Fragment>
                }
                imageSrc="/external/logopolygon1318-449p.svg"
              ></ChevronAssetChild>
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text29">GNO</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text30">12%</span>
                  </Fragment>
                }
                imageSrc="/external/Stakeway/assets/gnosis-400h.png"
              ></ChevronAssetChild>
              <div className="home-container13">
                <a
                  href="/asset-page1"
                  id="home-asset-view-all-button"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link2 button"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
          <img
            alt="Line61110"
            src="/external/line61110-6csu.svg"
            className="home-line6"
          />
          <div className="home-frame1171275258">
            <div className="home-frame4815122">
              <span className="home-text31">Yield per annum</span>
            </div>
            <span id="home-yield-per-annum" className="home-text32">
              $2546.98
            </span>
          </div>
        </div>
      </div>
      <div className="home-frame1171275238">
        <img
          alt="Frame1384"
          src="/external/frame1384-j36d.svg"
          className="home-frame1"
        />
        <img
          alt="Frame11712751261066"
          src="/external/frame11712751261066-0kom.svg"
          className="home-frame1171275126"
        />
        <img
          alt="flare11066"
          src="/external/flare11066-sh2h.svg"
          className="home-flare1"
        />
        <img
          alt="Frame1067"
          src="/external/frame1067-vbt.svg"
          className="home-frame2"
        />
        <img
          alt="availlogo9c818c5a11105"
          src="/external/availlogo9c818c5a11105-793-200h.png"
          className="home-availlogo9c818c5a1"
        />
        <img
          alt="Frame1105"
          src="/external/frame1105-bbqm.svg"
          className="home-frame3"
        />
        <img
          alt="Frame1105"
          src="/external/frame1105-1r9.svg"
          className="home-frame5"
        />
      </div>
      <div className="home-container14">
        <div className="home-container15">
          <StakewayCard rootClassName="stakeway-cardroot-class-name1"></StakewayCard>
          <StakewayCard
            text="We're among top 10 Lido validators"
            image="/external/Stakeway/assets/lido-400h.png"
            label="TOP 10"
            rootClassName="stakeway-cardroot-class-name"
          ></StakewayCard>
        </div>
        <div className="home-us-ps">
          <div className="home-content">
            <div className="home-frame482119">
              <div className="home-frame1171275281">
                <img
                  alt="banking0111384"
                  src="/external/banking0111384-9w5l.svg"
                  className="home-banking011"
                />
              </div>
              <div className="home-frame482177">
                <span className="home-text33">
                  <span>Non-custodial</span>
                  <br></br>
                  <span>staking</span>
                </span>
              </div>
            </div>
            <div className="home-frame482120">
              <div className="home-frame1171275282">
                <img
                  alt="banking0211384"
                  src="/external/banking0211384-h38s.svg"
                  className="home-banking021"
                />
              </div>
              <div className="home-frame482131">
                <span className="home-text37">
                  <span>Compliance and</span>
                  <br></br>
                  <span>risk mitigation</span>
                </span>
              </div>
            </div>
            <div className="home-frame482121">
              <div className="home-frame1171275283">
                <img
                  alt="banking2311384"
                  src="/external/banking2311384-loit.svg"
                  className="home-banking231"
                />
              </div>
              <div className="home-frame482176">
                <span className="home-text41">
                  <span>Immaculate</span>
                  <br></br>
                  <span>performance</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container16">
          <StakewayCard
            text="Effective uptime"
            image="/external/Stakeway/assets/server-400h.png"
            label="99.9%"
            rootClassName="stakeway-cardroot-class-name2"
          ></StakewayCard>
          <StakewayCard
            text="With APR up to 12%"
            image="/external/Stakeway/assets/gnosis-400h.png"
            label="12%"
            rootClassName="stakeway-cardroot-class-name3"
          ></StakewayCard>
        </div>
      </div>
      <div className="home-frame1171275274">
        <div className="home-frame1171275273">
          <span className="home-text45">Custom built infrastructure</span>
          <a
            href="/book-a-call"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link3"
          >
            <div className="home-frame1171275243">
              <span className="home-text46">ORDER A PROJECT</span>
              <img
                alt="bullseye1067"
                src="/external/bullseye1067-stx9.svg"
                className="home-bullseye"
              />
            </div>
          </a>
        </div>
        <span className="home-text47">
          We serve most demanding clients with custom infrastructure solutions
        </span>
      </div>
      <div className="home-highlight2">
        <div className="home-container17">
          <div className="home-container18">
            <div className="home-title">
              <img
                alt="image"
                src="/external/Stakeway/assets/pwc-200h.png"
                className="home-image2"
              />
              <span className="home-text48">PwC audited since 2021</span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container19">
        <StakewayCardReverse
          text="Have a setup in Puerto-Rico for tax advantages or Switzerland for crypto-friendliness"
          image="/external/Stakeway/assets/planet-400h.png"
          label="Any place"
        ></StakewayCardReverse>
        <div className="home-container20">
          <div className="home-frame11712752542">
            <span className="home-text49">35 add-ons</span>
            <span className="home-text50">
              Our Staking infrastructure offers over 35 customisations from our
              partners
            </span>
            <img
              alt="image"
              src="/external/Stakeway/assets/addons-700w.png"
              className="home-image3"
            />
          </div>
        </div>
      </div>
      <div className="home-container21">
        <div className="home-frame1171275277">
          <div className="home-frame1171275275">
            <span className="home-text51">
              <span>Staking market</span>
              <br></br>
              <span>outlook</span>
            </span>
            <span className="home-text55">
              This is what has been happening in ‘23, and what’s coming ahead
            </span>
            <Link to="/blog-staking-market" className="home-navlink1">
              <div className="home-frame1171275247">
                <span className="home-text56">Learn more</span>
                <img
                  alt="chevronright1067"
                  src="/external/chevronright1067-742.svg"
                  className="home-chevronright"
                />
              </div>
            </Link>
          </div>
          <div className="home-frame1171275276">
            <Link to="/blog-staking-rewards" className="home-navlink2">
              <div className="home-frame1171275248">
                <span className="home-text57">
                  Maximizing Staking Rewards: Strategies for Optimal Node
                  Performance
                </span>
              </div>
            </Link>
            <Link to="/blog-staking-security" className="home-navlink3">
              <div className="home-frame1171275249">
                <span className="home-text58">
                  Staking Security: Best Practices for Protecting Your Stake and
                  Node
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="home-container22">
        <StakewayProduct link="/custodians"></StakewayProduct>
        <StakewayProduct
          link="/pos-networks"
          text="Institutional crypto staking: Pooled fund delegation for optimized yields on your assets"
          label="Institution"
        ></StakewayProduct>
        <StakewayProduct
          link="/pos-networks"
          text="PoS network staking: Validator node operation for blockchain security and rewards"
          label="PoS network"
        ></StakewayProduct>
      </div>
      <div className="home-container23">
        <div className="home-frame1171275278">
          <span className="home-text59">Free Staking advice</span>
          <span className="home-text60">
            Connect to one of our Staking Experts to get free guidance on
            Staking
          </span>
          <div className="home-frame4816321">
            <div className="home-frame481630">
              <img
                alt="check1110"
                src="/external/check1110-wgra.svg"
                className="home-check1"
              />
              <span className="home-text61">No obligations</span>
            </div>
            <div className="home-frame481631">
              <img
                alt="check1110"
                src="/external/check1110-tf3h.svg"
                className="home-check2"
              />
              <span className="home-text62">Time that suits you</span>
            </div>
            <div className="home-frame4816322">
              <img
                alt="check1110"
                src="/external/check1110-m8j9.svg"
                className="home-check3"
              />
              <span className="home-text63">Casual conversation</span>
            </div>
          </div>
        </div>
        <iframe
          src="https://calendly.com/bogdan-jovicevic-gateway/30min"
          className="home-iframe"
        ></iframe>
      </div>
      <div className="home-frame1171275096">
        <div className="home-frame1171275064">
          <div className="home-frame41">
            <div className="home-frame81">
              <div className="home-frame1171275115">
                <div className="home-ethereumlogo20141">
                  <div className="home-group">
                    <img
                      alt="Vector1110"
                      src="/external/vector1110-zl48g.svg"
                      className="home-vector1"
                    />
                    <img
                      alt="Vector1110"
                      src="/external/vector1110-w7fn.svg"
                      className="home-vector2"
                    />
                    <img
                      alt="Vector1110"
                      src="/external/vector1110-vf95.svg"
                      className="home-vector3"
                    />
                    <img
                      alt="Vector1110"
                      src="/external/vector1110-bvw4.svg"
                      className="home-vector4"
                    />
                    <img
                      alt="Vector1110"
                      src="/external/vector1110-kot5.svg"
                      className="home-vector5"
                    />
                    <img
                      alt="Vector1110"
                      src="/external/vector1110-3w46.svg"
                      className="home-vector6"
                    />
                  </div>
                </div>
              </div>
              <div className="home-group11">
                <span className="home-text64">ETH</span>
              </div>
              <div className="home-group21">
                <span className="home-text65">Ethereum</span>
              </div>
            </div>
            <img
              alt="Line51110"
              src="/external/line51110-7rq.svg"
              className="home-line51"
            />
            <span id="apr-ethereum" className="home-text66">
              3.09% APR
            </span>
          </div>
        </div>
        <div className="home-frame1171275063">
          <div className="home-frame42">
            <div className="home-frame82">
              <img
                alt="Fill11110"
                src="/external/btc-logo-200h.png"
                className="home-fill1"
              />
              <div className="home-group12">
                <span className="home-text67">BTC</span>
              </div>
              <div className="home-group22">
                <span className="home-text68">Bitcoin</span>
              </div>
            </div>
            <img
              alt="Line51110"
              src="/external/line51110-yzp.svg"
              className="home-line52"
            />
            <span id="apr-bitcoin" className="home-text69">
              ~10% APR
            </span>
          </div>
        </div>
        <div className="home-frame12">
          <div className="home-frame43">
            <div className="home-frame83">
              <img
                alt="Polygon1110"
                src="/external/polygon1110-b058.svg"
                className="home-polygon"
              />
              <div className="home-group13">
                <span className="home-text70">
                  <span>POL</span>
                  <br></br>
                </span>
              </div>
              <div className="home-group23">
                <span className="home-text73">Polygon</span>
              </div>
            </div>
            <img
              alt="Line51110"
              src="/external/line51110-93o.svg"
              className="home-line53"
            />
            <span id="apr-polygon" className="home-text74">
              6.07% APR
            </span>
          </div>
        </div>
        <div className="home-frame1171275062">
          <div className="home-frame44">
            <div className="home-frame84">
              <img
                alt="gnosisgnognologo11110"
                src="/external/gnosisgnognologo11110-31e5.svg"
                className="home-gnosisgnognologo1"
              />
              <div className="home-group14">
                <span className="home-text75">GNO</span>
              </div>
              <div className="home-group24">
                <span className="home-text76">Gnosis</span>
              </div>
            </div>
            <img
              alt="Line51110"
              src="/external/line51110-njyo.svg"
              className="home-line54"
            />
            <span id="apr-gnosis" className="home-text77">
              9.75% APR
            </span>
          </div>
        </div>
      </div>
      <div className="home-frame1171275280">
        <span className="home-text78">FAQ</span>
        <div className="home-frame1171275279">
          <div className="home-frame1171275098">
            <span className="home-text79">Gateway staking</span>
            <img
              alt="ChevronLeft1110"
              src="/external/chevronleft1110-kcz-200h.png"
              className="home-chevron-left1"
            />
          </div>
          <div className="home-frame1171275099">
            <span className="home-text80">Slashing</span>
            <img
              alt="ChevronLeft1110"
              src="/external/chevronleft1110-9evn-200h.png"
              className="home-chevron-left2"
            />
          </div>
          <div className="home-frame1171275100">
            <span className="home-text81">Trusted partner</span>
            <img
              alt="ChevronLeft1110"
              src="/external/chevronleft1110-xbaoq-200h.png"
              className="home-chevron-left3"
            />
          </div>
        </div>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default Home
