import React from 'react'

import PropTypes from 'prop-types'

import './button-small-secondary.css'

const ButtonSmallSecondary = (props) => {
  return (
    <a
      href="https://www.stakingrewards.com/stake-app?input=bitcoin&amp;type=babylon-staking&amp;provider=stakeway"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div
        className={`button-small-secondary-container1 ${props.rootClassName} `}
      >
        <div className="button-small-secondary-container2">
          <span className="button-small-secondary-text">{props.label}</span>
        </div>
      </div>
    </a>
  )
}

ButtonSmallSecondary.defaultProps = {
  rootClassName: '',
  label: 'Small main button',
}

ButtonSmallSecondary.propTypes = {
  rootClassName: PropTypes.string,
  label: PropTypes.string,
}

export default ButtonSmallSecondary
