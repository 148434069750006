import React from 'react'

import PropTypes from 'prop-types'

import FooterLink from './footer-link'
import InputField from './input-field'
import ButtonPrimarySubtitle from './button-primary-subtitle'
import './stakeway-footer.css'

const StakewayFooter = (props) => {
  return (
    <div className="stakeway-footer-container1">
      <div className="stakeway-footer-container2">
        <div className="stakeway-footer-content">
          <div className="stakeway-footer-column1">
            <div className="stakeway-footer-products">
              <span className="stakeway-footer-text1">Products</span>
              <FooterLink
                link="/custodians"
                label="Custodians"
                className="stakeway-footer-component10"
              ></FooterLink>
              <FooterLink
                link="/institutions"
                label="Institutions"
                className="stakeway-footer-component11"
              ></FooterLink>
              <FooterLink
                link="/pos-networks"
                label="PoS networks"
                className="stakeway-footer-component12"
              ></FooterLink>
            </div>
            <div className="stakeway-footer-company">
              <span className="stakeway-footer-text2">Company</span>
              <FooterLink
                link="/about"
                label="About us"
                className="stakeway-footer-component13"
              ></FooterLink>
              <FooterLink
                link="/book-a-call"
                label="Book a call"
                className="stakeway-footer-component14"
              ></FooterLink>
              <FooterLink
                link="/research"
                label="Research"
                className="stakeway-footer-component15"
              ></FooterLink>
              <FooterLink
                link="/infrastructure"
                label="Infrastructure"
                className="stakeway-footer-component16"
              ></FooterLink>
              <FooterLink
                link="/privacy-policy"
                label="Privacy Policy"
                className="stakeway-footer-component17"
              ></FooterLink>
              <FooterLink
                link="/terms-of-service"
                label="Terms of Service"
                className="stakeway-footer-component18"
              ></FooterLink>
            </div>
          </div>
          <div className="stakeway-footer-column2">
            <div className="stakeway-footer-developers">
              <span className="stakeway-footer-text3">Articles</span>
              <FooterLink
                link="/blog-staking-market"
                main="Sign up"
                label="Market outlook"
                className="stakeway-footer-component19"
              ></FooterLink>
              <FooterLink
                link="/blog-staking-rewards"
                label="Rewards"
                className="stakeway-footer-component20"
              ></FooterLink>
              <FooterLink
                link="/blog-staking-security"
                label="Security"
                className="stakeway-footer-component21"
              ></FooterLink>
            </div>
            <div className="stakeway-footer-socials">
              <a
                href="https://gatewayfm.medium.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="stakeway-footer-link1"
              >
                <img
                  alt="Medium6985"
                  src="/external/ico-medium.svg"
                  className="stakeway-footer-medium"
                />
              </a>
              <a
                href="https://x.com/gateway_eth"
                target="_blank"
                rel="noreferrer noopener"
                className="stakeway-footer-link2"
              >
                <img
                  alt="TwitterX6985"
                  src="/external/ico-twitterx.svg"
                  className="stakeway-footer-twitter-x"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/gateway-fm/"
                target="_blank"
                rel="noreferrer noopener"
                className="stakeway-footer-link3"
              >
                <img
                  alt="LinkedIn6985"
                  src="/external/ico-linkedin.svg"
                  className="stakeway-footer-linked-in"
                />
              </a>
              <a
                href="https://t.me/gateway_fm"
                target="_blank"
                rel="noreferrer noopener"
                className="stakeway-footer-link4"
              >
                <img
                  alt="Telegram6985"
                  src="/external/ico-telegram.svg"
                  className="stakeway-footer-telegram"
                />
              </a>
              <a
                href="https://discord.gg/77vtmKNbkf"
                target="_blank"
                rel="noreferrer noopener"
                className="stakeway-footer-link5"
              >
                <img
                  alt="Discord"
                  src="/external/ico-discord.svg"
                  className="stakeway-footer-discord"
                />
              </a>
            </div>
            <div className="stakeway-footer-stay-in-touch">
              <span className="stakeway-footer-text4">Stay in touch</span>
              <span className="stakeway-footer-text5">
                Sign up for updates of our unbreakable web3 infrastructure
              </span>
              <form id="subscribeForm" className="stakeway-footer-form">
                <InputField
                  id="subscribeEmail"
                  label="Your email"
                  nameValue="email"
                  placeholder="mail@example.com"
                ></InputField>
                <ButtonPrimarySubtitle
                  main="Sign up"
                  divID="updateSignup"
                  supportive=" "
                ></ButtonPrimarySubtitle>
                <div id="signupMessage" className="stakeway-footer-message">
                  <span className="stakeway-footer-text6">It’s on!</span>
                </div>
              </form>
            </div>
          </div>
          <img
            alt="Frame4814906985"
            src="/external/Stakeway/assets/stakeway-icon.svg"
            className="stakeway-footer-logo"
          />
        </div>
      </div>
    </div>
  )
}

StakewayFooter.defaultProps = {
  rootClassName: '',
}

StakewayFooter.propTypes = {
  rootClassName: PropTypes.string,
}

export default StakewayFooter
