import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import ContentTitleProduct from '../components/content-title-product'
import Floating from '../components/floating'
import StakewayFooter from '../components/stakeway-footer'
import './infrastructure.css'

const Infrastructure = (props) => {
  return (
    <div className="infrastructure-container1">
      <Helmet>
        <title>Infrastructure - Stakeway</title>
        <meta property="og:title" content="Infrastructure - Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="infrastructure-hero">
        <div className="infrastructure-frame1171275211">
          <span className="infrastructure-text10">
            <span>INFRASTRUCTURE</span>
            <br></br>
          </span>
          <img
            alt="cubebitmin17156"
            src="/external/research-1500w.png"
            className="infrastructure-cubebitmin1"
          />
          <div className="infrastructure-frame482057">
            <span className="infrastructure-text13">
              <span>Empowering secure and reliable staking</span>
              <br></br>
              <span>solutions with Bare Metal infrastructure</span>
            </span>
          </div>
        </div>
      </div>
      <div className="infrastructure-container2">
        <span className="infrastructure-text17">
          <span>
            Stakeway provides enterprise-grade staking infrastructure utilizing
            dedicated bare metal servers, ensuring high reliability and security
            for managing digital assets. With a Total Value Locked (TVL) of
            approximately $1 billion, Stakeway supports major networks like
            Ethereum and Lido while maintaining a 99.9% uptime record.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Enterprise-Grade Staking Infrastructure"></ContentTitleProduct>
        <span className="infrastructure-text20">
          <span>
            Stakeway leverages a bare metal architecture that utilizes dedicated
            servers instead of virtual machines (VMs). This setup minimizes
            risks associated with accidental slashing, ensuring uninterrupted
            staking services. The redundancy of multiple validator nodes is
            built into the system, allowing continuous operation even during
            technical issues or downtime, which is critical for maintaining
            performance and trust.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Robust Performance and Security"></ContentTitleProduct>
        <span className="infrastructure-text23">
          Our infrastructure supports a Total Value Locked (TVL) of around $1
          billion, highlighting its capacity to handle significant staking
          operations. Stakeway operates validators for prominent networks,
          including Ethereum, Lido, Gnosis and Flare Network. The commitment to
          reliability and performance enables clients to maximize their staking
          returns while ensuring the safety of their digital assets.
        </span>
        <ContentTitleProduct text="Continuous Monitoring and Support"></ContentTitleProduct>
        <span className="infrastructure-text24">
          The infrastructure is complemented by a dedicated support system that
          includes 24/7 monitoring and an impressive 99.9% uptime history.
          Automated tools for penetration testing and evolving CI/CD practices
          ensure the security and integrity of the network. Clients receive
          expert consultancy at every step of their decentralization journey,
          reinforcing our commitment to customer service and operational
          excellence.
        </span>
        <ContentTitleProduct text="Growing Adoption and Innovative Solutions"></ContentTitleProduct>
        <span className="infrastructure-text25">
          <span>
            The demand for Stakeway staking-as-a-service solutions is evident,
            with significant participation in staking networks. The
            infrastructure is designed to support continuous onboarding of new
            protocols, allowing Stakeway to remain at the forefront of the
            evolving digital asset landscape. As the market for yield-generating
            digital assets expands, Stakeway is well-positioned to capitalize on
            emerging opportunities while maintaining its high standards for
            security and reliability.
          </span>
          <br></br>
        </span>
        <div className="infrastructure-scheme"></div>
        <Floating
          label="Stake with us"
          buttonLink="/book-a-call"
          buttonLabel="Stake now"
          description="With over $1B TVL we’re top 15% Lido validator. Stake safely with Stakeway"
          buttonSubtext="Provide your unique case"
          rootClassName="floatingroot-class-name16"
          className="infrastructure-component6"
        ></Floating>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default Infrastructure
