import React from 'react'

import PropTypes from 'prop-types'

import Tag from './tag'
import './blog-post-featured.css'

const BlogPostFeatured = (props) => {
  return (
    <div className="blog-post-featured-container">
      <div className="blog-post-featured-featuredpost">
        <img
          alt="gatewaywirexcasestudy17177"
          src={props.image}
          className="blog-post-featured-gatewaywirexcasestudy1"
        />
        <div className="blog-post-featured-featuredtitle">
          <div className="blog-post-featured-featuredtags">
            <Tag text="latest" rootClassName="tagroot-class-name"></Tag>
            <Tag text={props.tag} rootClassName="tagroot-class-name1"></Tag>
          </div>
          <span className="blog-post-featured-text1">{props.header}</span>
          <span className="blog-post-featured-text2">{props.date}</span>
        </div>
      </div>
    </div>
  )
}

BlogPostFeatured.defaultProps = {
  image: '/external/gatewaywirexcasestudy17177-zzw-1500w.png',
  header: 'A Partnership That Transformed The Crypto-Financial Industry',
  date: '12 of June 2024',
  tag: 'partnership',
}

BlogPostFeatured.propTypes = {
  image: PropTypes.string,
  header: PropTypes.string,
  date: PropTypes.string,
  tag: PropTypes.string,
}

export default BlogPostFeatured
