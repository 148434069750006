import React from 'react'

import PropTypes from 'prop-types'

import Tag from './tag'
import './blog-post-regular.css'

const BlogPostRegular = (props) => {
  return (
    <div className={`blog-post-regular-container1 ${props.rootClassName} `}>
      <div className="blog-post-regular-container2">
        <img
          alt="devnet17177"
          src={props.image}
          className="blog-post-regular-devnet1"
        />
        <div className="blog-post-regular-texts">
          <Tag text="guide"></Tag>
          <span className="blog-post-regular-text1">{props.header}</span>
          <span className="blog-post-regular-text2">{props.date}</span>
        </div>
      </div>
    </div>
  )
}

BlogPostRegular.defaultProps = {
  rootClassName: '',
  image: '/external/devnet17177-t7zo-1500w.png',
  header: 'Choosing a DA Solution: Third-Party, Local or DACs?',
  date: '12 of June 2024',
}

BlogPostRegular.propTypes = {
  rootClassName: PropTypes.string,
  image: PropTypes.string,
  header: PropTypes.string,
  date: PropTypes.string,
}

export default BlogPostRegular
