import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import ContentTitleProduct from '../components/content-title-product'
import Floating from '../components/floating'
import Footer from '../components/footer'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container1">
      <Helmet>
        <title>About - Stakeway</title>
        <meta property="og:title" content="About - Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="about-hero">
        <div className="about-frame1171275211">
          <span className="about-text10">
            <span>ABOUT US</span>
            <br></br>
          </span>
          <img
            alt="cubebitmin17156"
            src="/external/stak-1500w.png"
            className="about-cubebitmin1"
          />
          <div className="about-frame482057">
            <span className="about-text13">
              Stakeway by Gateway is your trusted partner for
              institutional-grade staking and node operations in the rapidly
              evolving world of digital assets
            </span>
          </div>
        </div>
      </div>
      <div className="about-container2">
        <span className="about-text14">
          Stakeway offers enterprise-grade staking solutions with over $1
          billion in Total Value Locked (TVL), supporting networks like Lido,
          Gnosis and Ethereum. Our secure and reliable bare-metal infrastructure
          ensures optimal returns while protecting assets, backed by 24/7
          monitoring and a 99.9% uptime guarantee. With over $800 million in
          Assets Under Management (AUM) and a robust, secure infrastructure, we
          are poised to help institutions unlock the full potential of the
          rapidly growing digital asset market.
        </span>
        <ContentTitleProduct text="Cutting-Edge Security For Enterprise-Grade Infrastructure"></ContentTitleProduct>
        <span className="about-text15">
          <span>
            Stakeway infrastructure is designed to deliver unmatched reliability
            and security. Our bare-metal server architecture, housed in
            ISO-compliant, regionally distributed data centers ensures your
            assets are protected. Utilizing physical servers instead of virtual
            machines (VMs), we maintain redundancy across multiple validator
            nodes to prevent slashing and ensure continuous operation even
            during technical issues or downtime. With a proven 99.9% uptime
            history and 24/7 monitoring, Stakeway guarantees robust performance
            and asset security.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Staking Solutions and Expanding Protocol Support"></ContentTitleProduct>
        <span className="about-text18">
          At Stakeway we operate validators for major networks such as Lido,
          Gnosis, Flare, Celestia and the Ethereum Foundation. Managing a Total
          Value Locked (TVL) of around $1 billion. Our technical capabilities
          enable rapid onboarding of new protocols, typically within a two-week
          timeframe, ensuring we remain at the forefront of the evolving crypto
          ecosystem. This adaptability allows us to meet the diverse needs of
          institutional clients and to continually expand our range of supported
          networks.
        </span>
        <ContentTitleProduct text="Tailored Enterprise Products with Advanced Automation"></ContentTitleProduct>
        <span className="about-text19">
          Stakeway offers a suite of enterprise-focused solutions tailored to
          institutional needs. Our automated staking and node management system
          streamlines operations — while our comprehensive SDK &amp; API suite
          allows for integration with your infrastructure. The intuitive
          enterprise dashboard provides real-time visibility into node
          performance and reward tracking. Additionally, our liquid staking and
          restaking products open up new opportunities for maximizing yield.
          Giving institutions greater flexibility and control over their staked
          assets.
        </span>
        <ContentTitleProduct text="Innovative Research and Strategic Partnerships"></ContentTitleProduct>
        <span className="about-text20">
          <span>
            As part of the Gateway.fm Group, Stakeway benefits from a
            collaborative ecosystem, including Presto for roll-up deployment and
            other blockchain-focused R&amp;D initiatives. Our research arm is
            dedicated to advancing blockchain protocols and cryptographic
            technologies, ensuring Stakeway remains at the cutting edge of the
            decentralized finance (DeFi) landscape.
          </span>
          <br></br>
          <br></br>
          <span>
            We’ve been instrumental in major network transitions, including
            Proof-of-Stake migrations and our validators consistently rank in
            the top 10% for performance. These achievements underscore our role
            as a key player in global decentralization initiatives.
          </span>
          <br></br>
        </span>
        <ContentTitleProduct text="Leader in Institutional Staking"></ContentTitleProduct>
        <span className="about-text26">
          <span>
            Stakeway is your trusted partner in navigating the complex world of
            digital asset staking. Our expertise in staking Ethereum, Bitcoin
            and emerging protocols ensures that your assets are not only secure
            but also generating substantial returns.
          </span>
          <br></br>
          <br></br>
          <span>
            Whether you&apos;re an institution looking to capitalize on
            yield-generating opportunities or exploring new blockchain
            innovations, Stakeway provides the support, infrastructure and
            expertise necessary for success.
          </span>
          <br></br>
          <br></br>
          <span>
            Join us at Stakeway and let&apos;s shape the future of finance
            together.
          </span>
          <br></br>
        </span>
        <div className="about-scheme"></div>
        <Floating
          label="Stake with us"
          buttonLink="/book-a-call"
          buttonLabel="Stake now"
          description="With over $1B TVL we’re top 15% Lido validator. Stake safely with Stakeway"
          buttonSubtext="Provide your unique case"
          rootClassName="floatingroot-class-name17"
          className="about-component7"
        ></Floating>
      </div>
      <Footer rootClassName="footerroot-class-name48"></Footer>
    </div>
  )
}

export default About
