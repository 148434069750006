import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import TitleSidePage from '../components/title-side-page'
import BlogPostFeatured from '../components/blog-post-featured'
import BlogPostRegular from '../components/blog-post-regular'
import Footer from '../components/footer'
import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-container1">
      <Helmet>
        <title>Gateway.fm Blog</title>
        <meta property="og:title" content="Gateway.fm Blog" />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name2"></Navigation>
      <div className="blog-container2">
        <div className="blog-container3">
          <TitleSidePage
            text="NEWS, UPDATES &amp; GUIDES"
            rootClassName="title-side-pageroot-class-name"
          ></TitleSidePage>
          <BlogPostFeatured
            tag="new service"
            date="2 of July 2024"
            image="/external/blog-banners/gateway-witnesschain-mainnet-polygon-cdk-agglayer-1500w.png"
            header="A highlight is the integration of our mainnet with the AGGLAYER."
            className="blog-component12"
          ></BlogPostFeatured>
          <BlogPostFeatured
            tag="new service"
            date="17 of June 2024"
            image="/external/blog-banners/gateway-node-sale-1500w.png"
            header="Node Sales Is A Way To Raise Funds With Tangible Assets"
            className="blog-component13"
          ></BlogPostFeatured>
          <div className="blog-row1">
            <BlogPostRegular
              date="7th of June 2024"
              image="/external/blog-banners/gateway-wirex-case-study-1500w.png"
              header="A Partnership That Transformed The Crypto-Financial Industry"
              rootClassName="blog-post-regularroot-class-name1"
              className="blog-component14"
            ></BlogPostRegular>
            <BlogPostRegular
              date="15 of May 2024"
              image="/external/sandbox-1500w.png"
              header="Devnet, Testnet or Mainnet deployment?"
              rootClassName="blog-post-regularroot-class-name"
              className="blog-component15"
            ></BlogPostRegular>
          </div>
          <div className="blog-container4">
            <Link to="/blog-staking-security" className="blog-navlink">
              <BlogPostRegular
                date="13 of May 2024"
                className="blog-component16"
              ></BlogPostRegular>
            </Link>
            <BlogPostRegular
              date="7th of May 2024"
              image="/external/blog-banners/gatway-lukso-partnership-1500w.webp"
              header="LUKSO &amp; Gateway.fm: A Successful Partnership"
              className="blog-component17"
            ></BlogPostRegular>
          </div>
          <div className="blog-container5">
            <BlogPostRegular
              date="7th of May 2024"
              image="/external/blog-banners/gateway-stellar-partnership-1500w.webp"
              header="Gateway.fm Announces Role in Building Public Soroban RPC Infrastructure for Stellar"
              className="blog-component18"
            ></BlogPostRegular>
            <BlogPostRegular
              date="3rd of May 2024"
              image="/external/blog-banners/gateway-gpt-protocol-partnership-1500w.webp"
              header="How is GPT Protocol Building a Decentralized AI Network?"
              className="blog-component19"
            ></BlogPostRegular>
          </div>
          <div className="blog-container6">
            <BlogPostRegular
              date="11th of March 2024"
              image="/external/blog-banners/gateway-gpt-protocol-partnership-announcement-1500w.webp"
              header="Gateway.fm and GPT Protocol Join Forces: Redefining Decentralized AI and Blockchain Integration"
              className="blog-component20"
            ></BlogPostRegular>
            <BlogPostRegular
              date="7th of March 2024"
              image="/external/blog-banners/gateway-near-partnership-1500w.webp"
              header="Gateway and NEAR Foundation Team Up for Next-Gen Data Availability"
              className="blog-component21"
            ></BlogPostRegular>
          </div>
          <BlogPostRegular
            date="5th of February 2024"
            image="/external/blog-banners/gateway-wirex-partnership-announcement-1500w.png"
            header="Gateway Partnership with Wirex"
            className="blog-component22"
          ></BlogPostRegular>
          <div className="blog-row2"></div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Blog
