import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import USPCheckmark from '../components/usp-checkmark'
import SubtitleProductHero from '../components/subtitle-product-hero'
import Footer from '../components/footer'
import './book-a-call.css'

const BookACall = (props) => {
  return (
    <div className="book-a-call-container1">
      <Helmet>
        <title>Book a call with Stakeway</title>
        <meta property="og:title" content="Book a call with Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="book-a-call-content">
        <div className="book-a-call-hero">
          <div className="book-a-call-description">
            <div className="book-a-call-container2">
              <span className="book-a-call-text">
                YOUR PERSONAL PROJECT MANAGER
              </span>
            </div>
            <div className="book-a-call-container3">
              <USPCheckmark
                text="No obligations"
                rootClassName="usp-checkmarkroot-class-name"
              ></USPCheckmark>
              <USPCheckmark text="Time that suits you"></USPCheckmark>
              <USPCheckmark text="Casual conversation"></USPCheckmark>
            </div>
            <SubtitleProductHero text="Zero-knowledge setup with highly competent personal project manager. Choose a suitable time, and we'll help with your project"></SubtitleProductHero>
          </div>
          <iframe
            src="https://calendly.com/bogdan-jovicevic-gateway/30min"
            className="book-a-call-iframe"
          ></iframe>
        </div>
      </div>
      <Footer rootClassName="footerroot-class-name14"></Footer>
    </div>
  )
}

export default BookACall
