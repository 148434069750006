import React from 'react'

import PropTypes from 'prop-types'

import './asset.css'

const Asset = (props) => {
  return (
    <div className={`asset-container1 ${props.rootClassName} `}>
      <div className="asset-container2">
        <div className="asset-content">
          <div className="asset-name">
            <div className="asset-logoethereum">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="asset-image"
              />
            </div>
            <div className="asset-frame1171275268">
              <span id={props.chainName} className="asset-text10">
                Ethereum
              </span>
              <span id={props.chainLabel} className="asset-text11">
                ETH
              </span>
            </div>
          </div>
          <div className="asset-container3">
            <div className="asset-price">
              <span id={props.priceChange} className="asset-text12">
                Price
              </span>
              <span id={props.priceValue} className="asset-text13">
                $3’088.97
              </span>
              <span id={props.priceChange} className="asset-text14">
                -7.55%
              </span>
            </div>
            <div className="asset-market-cap">
              <span id={props.priceChange} className="asset-text15">
                Market cap
              </span>
              <span id={props.marketCap} className="asset-text16">
                $100.7B
              </span>
              <span id={props.marketChange} className="asset-text17">
                -8.03%
              </span>
            </div>
          </div>
          <div className="asset-container4">
            <div className="asset-reward">
              <span id={props.priceChange} className="asset-text18">
                Reward
              </span>
              <span id={props.rewardRate} className="asset-text19">
                3.28%
              </span>
              <span id={props.rewardChange} className="asset-text20">
                +0.05%
              </span>
            </div>
            <div className="asset-action">
              <span className="asset-text21">STAKE</span>
              <img
                alt={props.vectorAlt}
                src={props.vectorSrc}
                className="asset-vector"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Asset.defaultProps = {
  priceChange: '-7.55%',
  imageAlt: 'image',
  rootClassName: '',
  chainName: 'Ethereum',
  marketChange: '-8.03%',
  vectorAlt: 'Vector1131',
  marketCap: '$100.7B',
  imageSrc: '/external/logo-ethereum1-200h.png',
  chainLabel: 'ETH',
  priceValue: "$3'088.97",
  rewardRate: '3.28%',
  vectorSrc: '/external/vector1131-l64c.svg',
  rewardChange: '+0.05%',
}

Asset.propTypes = {
  priceChange: PropTypes.string,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  chainName: PropTypes.string,
  marketChange: PropTypes.string,
  vectorAlt: PropTypes.string,
  marketCap: PropTypes.string,
  imageSrc: PropTypes.string,
  chainLabel: PropTypes.string,
  priceValue: PropTypes.string,
  rewardRate: PropTypes.string,
  vectorSrc: PropTypes.string,
  rewardChange: PropTypes.string,
}

export default Asset
