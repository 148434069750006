import React from 'react'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import Asset from '../components/asset'
import StakewayFooter from '../components/stakeway-footer'
import './asset-page.css'

const AssetPage = (props) => {
  return (
    <div className="asset-page-container1">
      <Helmet>
        <title>Asset-Page - Stakeway</title>
        <meta property="og:title" content="Asset-Page - Stakeway" />
      </Helmet>
      <StakewayNavigation rootClassName="stakeway-navigationroot-class-name1"></StakewayNavigation>
      <div id="asset_table" className="asset-page-content">
        <div className="asset-page-hero">
          <div className="asset-page-container2">
            <span className="asset-page-heading">
              <span>CHOOSE</span>
              <br></br>
              <span>ASSET</span>
              <br></br>
            </span>
          </div>
        </div>
        <Asset rootClassName="assetroot-class-name"></Asset>
        <Asset rootClassName="assetroot-class-name3"></Asset>
        <Asset rootClassName="assetroot-class-name2"></Asset>
        <Asset rootClassName="assetroot-class-name1"></Asset>
      </div>
      <div className="asset-page-container3">
        <button
          id="loadmore_assets"
          type="button"
          className="asset-page-button button"
        >
          Load More
        </button>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default AssetPage
