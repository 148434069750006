import React from 'react'

import PropTypes from 'prop-types'

import './stakeway-card.css'

const StakewayCard = (props) => {
  return (
    <div className={`stakeway-card-container ${props.rootClassName} `}>
      <div className="stakeway-card-frame1171275254">
        <span className="stakeway-card-text1">{props.text}</span>
        <span className="stakeway-card-text2">{props.label}</span>
        <img alt="image" src={props.image} className="stakeway-card-image" />
      </div>
    </div>
  )
}

StakewayCard.defaultProps = {
  image: '/external/block-400h-400h.png',
  rootClassName: '',
  label: '25K+',
  text: 'Nodes running in 8 regions',
}

StakewayCard.propTypes = {
  image: PropTypes.string,
  rootClassName: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
}

export default StakewayCard
