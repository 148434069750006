import React from 'react'

import PropTypes from 'prop-types'

import ButtonSmallSecondary from './button-small-secondary'
import './stakeway-highlight.css'

const StakewayHighlight = (props) => {
  return (
    <div className={`stakeway-highlight-container1 ${props.rootClassName} `}>
      <div className="stakeway-highlight-container2">
        <div className="stakeway-highlight-title">
          <img
            alt="windowclose1066"
            src="/external/windowclose1066-56rh.svg"
            className="remove-parent"
          />
          <span className="stakeway-highlight-text">{props.text}</span>
        </div>
        <ButtonSmallSecondary
          label={props.buttonLabel}
          rootClassName="button-small-secondaryroot-class-name"
        ></ButtonSmallSecondary>
      </div>
    </div>
  )
}

StakewayHighlight.defaultProps = {
  rootClassName: '',
  buttonLabel: 'Download',
  text: 'Q2 ‘24 Report: Steady Growth',
}

StakewayHighlight.propTypes = {
  rootClassName: PropTypes.string,
  buttonLabel: PropTypes.string,
  text: PropTypes.string,
}

export default StakewayHighlight
