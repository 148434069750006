import React from 'react'

import PropTypes from 'prop-types'

import './button-small-main.css'

const ButtonSmallMain = (props) => {
  return (
    <div
      id="button-small-main"
      className={`button-small-main-container ${props.rootClassName} `}
    ></div>
  )
}

ButtonSmallMain.defaultProps = {
  rootClassName: '',
}

ButtonSmallMain.propTypes = {
  rootClassName: PropTypes.string,
}

export default ButtonSmallMain
