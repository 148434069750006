import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navigation from '../components/navigation'
import Career from '../components/career'
import Footer from '../components/footer'
import './careers.css'

const Careers = (props) => {
  return (
    <div className="careers-container1">
      <Helmet>
        <title>Careers - Stakeway</title>
        <meta property="og:title" content="Careers - Stakeway" />
      </Helmet>
      <Navigation rootClassName="navigationroot-class-name38"></Navigation>
      <div className="careers-container2">
        <div className="careers-title">
          <div className="careers-subtext">
            <span className="careers-text1">CAREERS</span>
            <span className="careers-text2">
              <span>Join a fast growing</span>
              <br></br>
              <span>Web3 infrastructure company</span>
            </span>
          </div>
        </div>
        <Link to="/careers/business" className="careers-navlink1">
          <Career
            tag1="mid level"
            tag2="remote"
            tag3="immediate"
            className="careers-component2"
          ></Career>
        </Link>
        <Link to="/careers/business2" className="careers-navlink2">
          <Career
            tag1="manager"
            tag2="remote"
            tag3="immediate"
            label="Business Development Marketing Manager"
            subtext="Seeking a data-driven Marketing Manager to elevate brand awareness and community engagement for Presto, a zkEVM roll-up platform, through innovative strategies and ecosystem partnerships"
            className="careers-component3"
          ></Career>
        </Link>
      </div>
      <Footer rootClassName="footerroot-class-name37"></Footer>
    </div>
  )
}

export default Careers
