import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import NavigationLink from './navigation-link'
import ButtonSmallMain from './button-small-main'
import './stakeway-navigation.css'

const StakewayNavigation = (props) => {
  return (
    <div className={`stakeway-navigation-container1 ${props.rootClassName} `}>
      <div className="stakeway-navigation-container2">
        <Link to="/" className="stakeway-navigation-navlink1">
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="stakeway-navigation-image1"
          />
        </Link>
        <img
          alt={props.imageAlt1}
          src={props.imageSrc1}
          className="stakeway-navigation-image2"
        />
        <div id="links-block" className="stakeway-navigation-links-block">
          <div className="stakeway-navigation-content">
            <div className="stakeway-navigation-links">
              <NavigationLink
                link="/asset-page"
                label="Assets"
                className="stakeway-navigation-component1"
              ></NavigationLink>
              <Link to="/custodians" className="stakeway-navigation-navlink2">
                <div className="stakeway-navigation-container3">
                  <span className="stakeway-navigation-text1">
                    {props.label}
                  </span>
                </div>
              </Link>
              <NavigationLink
                link="/institutions"
                label="Institutions"
                className="stakeway-navigation-component2"
              ></NavigationLink>
              <NavigationLink
                link="/pos-networks"
                label="PoS networks"
                className="stakeway-navigation-component3"
              ></NavigationLink>
              <NavigationLink
                link="/infrastructure"
                label="Infrastructure"
                className="stakeway-navigation-component4"
              ></NavigationLink>
              <NavigationLink
                link="/research"
                label="Research"
                className="stakeway-navigation-component5"
              ></NavigationLink>
              <NavigationLink
                link="/about"
                label="About"
                className="stakeway-navigation-component6"
              ></NavigationLink>
            </div>
            <div className="stakeway-navigation-sign-in">
              <ButtonSmallMain
                divID="STAKE NOW"
                label="STAKE NOW"
                className="stakeway-navigation-component7"
              ></ButtonSmallMain>
            </div>
            <a
              href="/book-a-call"
              target="_blank"
              rel="noreferrer noopener"
              className="stakeway-navigation-link"
            >
              <div
                id="stake-now-navigation-button"
                className="stakeway-navigation-action"
              >
                <span className="stakeway-navigation-text2">STAKE NOW</span>
                <img
                  alt={props.vectorAlt}
                  src={props.vectorSrc}
                  className="stakeway-navigation-vector"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

StakewayNavigation.defaultProps = {
  label: 'Custodians',
  imageSrc: '/external/Stakeway/assets/stakeway-logo.svg',
  rootClassName: '',
  imageSrc1: '/external/Stakeway/assets/stakeway-icon.svg',
  vectorSrc: '/external/vector1131-l64c.svg',
  imageAlt: 'image',
  vectorAlt: 'Vector1131',
  imageAlt1: 'image',
}

StakewayNavigation.propTypes = {
  label: PropTypes.string,
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  imageSrc1: PropTypes.string,
  vectorSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  vectorAlt: PropTypes.string,
  imageAlt1: PropTypes.string,
}

export default StakewayNavigation
