import React from 'react'

import PropTypes from 'prop-types'

import './stakeway-card-reverse.css'

const StakewayCardReverse = (props) => {
  return (
    <div className="stakeway-card-reverse-container">
      <div className="stakeway-card-reverse-frame1171275254">
        <span className="stakeway-card-reverse-text1">{props.label}</span>
        <span className="stakeway-card-reverse-text2">{props.text}</span>
        <img
          alt="image"
          src={props.image}
          className="stakeway-card-reverse-image"
        />
      </div>
    </div>
  )
}

StakewayCardReverse.defaultProps = {
  label: '25K+',
  text: 'Nodes running in 8 regions',
  rootClassName: '',
  image: '/external/block-400h-400h.png',
}

StakewayCardReverse.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  image: PropTypes.string,
}

export default StakewayCardReverse
